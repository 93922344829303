import React from "react";
import { BodyText } from "../../texts/BodyText";
import { Hero } from "../../texts/Hero";
import { Box, Flex, Link, Stack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { ImageCollageService } from "../../visuals/ImageCollageService";

export function InsightHero() {
  return (
    <Flex
      as="section"
      display="flex"
      w="100vw"
      flex="1 1 auto"
      mt={{
        base: "3rem",
        lg: 0,
      }}
      px={{ base: "1rem", md: "3rem" }}
      pl={{
        lg: 9,
        xl: "10vw",
        "2xl": "10vw",
      }}
      flexDirection={{
        base: "column-reverse",
        lg: "row",
      }}
      alignItems={{
        base: "center",
        lg: "center",
      }}
      justifyContent={{
        base: "flex-end",
        lg: "center",
      }}
      gap={{
        base: "1rem",
        lg: "3rem",
      }}
    >
      <Box
        paddingBottom="2rem"
        pl={{
          base: 2,
          lg: "calc(calc(100% - 900px) / 2)",
          xl: "calc(calc(100% - 1024px) / 2)",
          "2xl": "calc(calc(100% - 1280px) / 2)",
        }}
        pr={{
          base: 2,
          lg: 8,
          "2xl": 16,
        }}
      >
        <Hero
          mt={{
            base: "2rem",
            lg: 0,
          }}
          mb="6"
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          Indevit insights
        </Hero>
        <BodyText
          textAlign={{
            base: "center",
            lg: "left",
          }}
        >
          Throughout the years, we have partnered with clients on some fantastic
          projects. Check out our stories and get a taste of the fun we have had
          working side by side!
        </BodyText>
      </Box>
      <ImageCollageService
        boxProps={{
          marginY: {
            base: 0,
            lg: "3rem",
          },
          maxWidth: {
            base: "30rem",
            lg: "40rem",
            "3xl": "50rem",
          },
        }}
        image={
          <StaticImage
            src="../../../images/Insights.png"
            alt=""
            placeholder="blurred"
          />
        }
      />
    </Flex>
  );
}
