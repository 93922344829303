import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import InsightSection from "../components/sections/insight/InsightSection";
import { InsightHero } from "../components/sections/insight/InsightHero";

function InsightPage() {
  return (
    <Layout colorScheme="secondary" heroSection={<InsightHero />} isNextSectionInitiallyVisible>
      <InsightSection />
    </Layout>
  )
}

export default InsightPage;

export const Head = () => (
  <MetaTags title="Insights" />
)