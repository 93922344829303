import { Section } from "../../layout/Section";
import React from "react";
import { Stack } from "@chakra-ui/react";
import InsightCategory from "./InsightCategory";

interface InsightSectionProps {}

const InsightSection: React.FC<InsightSectionProps> = () => {
  return (
    <Section>
      <Stack spacing={20}>
        <InsightCategory />
      </Stack>
    </Section>
  );
};
export default InsightSection;
