import { Box, Link, Stack } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React, { ReactNode } from "react";
import { Subtitle } from "../texts/Subtitle";
import { BodyText } from "../texts/BodyText";
import { Button } from "../inputs/Button";

interface VerticalTextAndBoxProps {
  image: ReactNode;
  subtitle: string;
  body: string;
  link: string;
  buttonColorScheme?: "primary" | "secondary";
}

const VerticalTextAndBox: React.FC<VerticalTextAndBoxProps> = ({
  image,
  subtitle,
  body,
  link,
  buttonColorScheme = "primary",
}) => {
  return (

    <Stack
      textAlign="center" // stack and box are do the same thing
      bg="white"
      overflow={"hidden"}
      display={"flex"}
      alignItems={"column"}>
      {image}
      <Box
        padding="24px"
        display="flex"
        flexDirection={"column"}
        flexGrow="1"
        justifyContent={"space-evenly"}
        alignItems="center">
        <Subtitle flexGrow="0" fontSize={{ base: "1rem", md: "1.25rem" }}>
          {subtitle}
        </Subtitle>
        <BodyText marginTop="16px" flex="1 1 auto" textAlign="center">
          {body}
        </BodyText>
        <Link marginTop="24px" flexGrow="0" to={link} as={GatsbyLink} >
          <Button colorScheme={buttonColorScheme}>Read More</Button>
        </Link>
      </Box>
    </Stack>
  );
};

export default VerticalTextAndBox;
